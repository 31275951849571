import React, { useState } from 'react';
import { Button, Form, Typography, Modal } from 'antd';
import { FormInput } from '../FormInput/FormInput';
import {
  useRemindPassword,
  UseRemindPasswordProps,
} from './../../utils/hooks/useRemindPassword';
import './ForgotPasswordForm.less';

const ForgotPasswordForm: React.FC = () => {
  const { handleRemindPassword, loading, error } = useRemindPassword();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { Paragraph, Text } = Typography;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (input: UseRemindPasswordProps) => {
    const { data } = await handleRemindPassword(input);

    if (data) {
      showModal();
    }
  };
  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Email jest wymagany!' }]}
          validateStatus={error ? 'error' : loading ? 'validating' : 'success'}
          hasFeedback={loading || error ? true : false}
        >
          <FormInput placeholder={'Email'} />
        </Form.Item>

        {error && (
          <Paragraph type="danger" className="errot-text">
            Brak podanego adresu w bazie danych
          </Paragraph>
        )}
        <Form.Item wrapperCol={{ span: 16 }}>
          <Button type="primary" className="btn-mobile" htmlType="submit">
            Odzyskaj hasło
          </Button>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="forgot-form-modal"
      >
        <Paragraph type="success" className="forgot-form-modal-text">
          Wysłaliśmy na Twojego maila link do zmiany hasła
          <Text>&#127881;</Text>
        </Paragraph>
      </Modal>
    </>
  );
};

export default ForgotPasswordForm;
