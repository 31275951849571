import React from 'react';
import { Typography, Layout, Row, Col } from 'antd';
import { globalStyles } from '../styles/variables';
import useMediaQuery from '../utils/hooks/useMediaQuery';
import ForgotPasswordForm from '../components/ForgotPasswordForm/ForgotPasswordForm';
import { BackgroundImage } from '../components/BackgroundImage/BackgroundImage';
import './forgot-password.less';
import { LogoInHeader } from '../components/LogoInHeader/LogoInHeader';

const ForgotPassword = () => {
  const { Title, Paragraph } = Typography;

  const { isDesktop, isTablet, matches } = useMediaQuery(
    globalStyles.minScreenWidth.lg
  );

  return (
    <Layout>
      <LogoInHeader />
      <Row align={isTablet ? 'middle' : 'top'} className="page-container">
        <Col
          span={matches ? 10 : 20}
          offset={isDesktop ? 4 : 2}
          className="forgot-password-page-col"
        >
          <Row className="forgot-password-page-form-wrapper">
            <Col span={24}>
              <Title level={3} className="forgot-password-page-heading">
                Zapomniałeś hasła?
              </Title>
              <Paragraph className="forgot-password-page-text">
                Wystarczy, że podasz swój e-mail, a my pomożemy Ci ustawić nowe
                hasło.
              </Paragraph>
              <ForgotPasswordForm />
            </Col>
          </Row>
        </Col>
      </Row>
      <BackgroundImage />
    </Layout>
  );
};

export default ForgotPassword;
