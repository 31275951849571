import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD_MUTATION } from '../../queries/forgotPasswordMutation';

export type UseRemindPasswordProps = {
  email: String;
};

export const useRemindPassword = () => {
  const [forgotPassword, { loading, error }] = useMutation(
    FORGOT_PASSWORD_MUTATION
  );
  const handleRemindPassword = useCallback(
    ({ email }: UseRemindPasswordProps) =>
      forgotPassword({
        variables: {
          email: `${email}`,
        },
        onCompleted: ({ forgotPassword }) => {
          return forgotPassword;
        },
        onError: error => {
          console.log('Error', error);
        },
      }),
    []
  );

  return { handleRemindPassword, loading, error };
};
